<script>
import ApiService from "@services/api.service";

export default {
  name: "LeadsType",
  data() {
    return {
      table: {
        sortBy: "code",
        fields: [
          { key: "code", sortable: true },
          { key: "description", sortable: true, label: "Leads Type" },
          { key: "leadsProgramDesc", sortable: true, label: "Leads Program" },
          { key: "leadsSource", sortable: true },
          { key: "status", class: "text-right" },
          { key: "actions", class: "text-right" }
        ],
        data: [],
        filter: null
      },
      parameter: {
        leadsProgramList: [],
        leadsSource: [
          { code: 0, description: "Own" },
          { code: 1, description: "Campaign" },
          { code: 2, description: "Sales Trigger" }
        ]
      },
      leadsType: {
        description: null,
        leadsProgramCode: null,
        leadsSource: null,
        code: null
      },
      editMode: false,
      showModal: false,
      loader: {
        table: false
      }
    };
  },
  computed: {
    rowCount: function () {
      return this.table.data.length;
    },
    leadsSourceOptions: function () {
      return this.parameter.leadsSource.filter(x => x.code != 0);
    }
  },
  created() {
    this.loadTable();
    this.loadLeadsProgram();
  },
  methods: {
    loadTable() {
      this.loader.table = true;
      ApiService.get("LeadsType")
        .then(resp => {
          this.table.data = resp;
        })
        .finally(() => {
          this.loader.table = false;
        });
    },
    loadLeadsProgram() {
      this.loader.program = true;
      ApiService.get("LeadsProgram")
        .then(resp => {
          this.parameter.leadsProgramList = resp;
        })
        .finally(() => {
          this.loader.program = false;
        });
    },
    openDetail(selectedRow) {
      if (selectedRow) {
        this.leadsType = { ...selectedRow };
        if (selectedRow.code === "OWN") this.leadsType.leadsSourceDesc = "OWN";
        this.editMode = true;
      }
      this.showModal = true;
    },
    filterLeadsSource(value) {
      var x = this.parameter.leadsSource.find(x => x.code == value);
      return x.description;
    },
    save() {
      if (!this.editMode) {
        this.changeLoader(true);
        ApiService.post("LeadsType", this.leadsType)
          .then(() => {
            this.showToast("success", "Create Successful");
            this.loadTable();
            this.showModal = false;
          })
          .finally(() => {
            this.changeLoader(false);
          });
      } else {
        this.changeLoader(true);
        ApiService.put("LeadsType", this.leadsType)
          .then(() => {
            this.showToast("success", "Edit Successful");
            this.loadTable();
            this.showModal = false;
          })
          .finally(() => {
            this.changeLoader(false);
          });
      }
    },
    toggle(code) {
      this.changeLoader(true);
      let qs = {
        code: code
      };
      ApiService.patch("LeadsType", null, qs)
        .then(() => {
          this.showToast("success", "Toggle Successful");
          this.loadTable();
        })
        .finally(() => {
          this.changeLoader(false);
        });
    },
    clearForm() {
      this.leadsType = {
        description: null,
        leadsProgramCode: null,
        leadsSource: null,
        code: null
      };
      this.editMode = false;
    }
  }
};
</script>

<template>
  <div>
    <b-card>
      <div class="d-flex mb-2">
        <b-btn variant="outline-primary" @click.stop="openDetail(null)">Create</b-btn>
        <div class="ml-auto">
          <b-input v-model="table.filter" placeholder="Filter" debounce="500" class="form-control text-right" />
        </div>
      </div>

      <b-table
        :items="table.data"
        :fields="table.fields"
        :sort-by.sync="table.sortBy"
        :filter="table.filter"
        :busy="loader.table"
        no-provider-filtering
        empty-text="No data to show"
        striped
        outlined
        hover
        show-empty
        fixed
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(leadsSource)="data">
          <div>
            {{ filterLeadsSource(data.item.leadsSource) }}
          </div>
        </template>
        <template #cell(status)="data">
          <div>
            <b-badge :variant="data.item.isActive ? 'success' : 'warning'" class="text-capitalize">
              {{ data.item.isActive ? "Active" : "Inactive" }}
            </b-badge>
          </div>
        </template>
        <template #cell(actions)="data">
          <div>
            <b-btn
              v-if="data.item.code !== 'OWN'"
              class="btn btn-sm btn-wide mr-1"
              :class="data.item.isActive ? 'btn-secondary' : 'btn-info'"
              @click.stop="toggle(data.item.code)"
              >{{ data.item.isActive ? "Deactivate" : "Activate" }}</b-btn
            >
            <b-btn class="btn btn-sm btn-wide btn-warning mr-1" @click.stop="openDetail(data.item)">Edit</b-btn>
            <!-- <b-btn class="btn btn-sm btn-wide btn-danger" @click.stop="openDetail(data.item)">Delete</b-btn> -->
          </div>
        </template>
      </b-table>
    </b-card>

    <b-modal v-model="showModal" size="lg" hide-footer centered :backdrop="true" @hidden="clearForm">
      <div class="px-3 py-2">
        <b-row>
          <b-col class="text-center">
            <h2>{{ !editMode ? `Create New Leads Type` : `Edit Leads Type` }}</h2>
          </b-col>
        </b-row>
        <hr />
        <b-form @submit.stop.prevent="save">
          <b-row class="mb-2">
            <b-col md="4">
              <label>Code</label>
            </b-col>
            <b-col md="8">
              <b-input v-model="leadsType.code" class="form-control" :disabled="editMode" />
              <small>Leads Code</small>
            </b-col>
          </b-row>
          <b-row v-if="leadsType.code != 'OWN'" class="mb-2">
            <b-col md="4">
              <label>Program</label>
            </b-col>
            <b-col md="8">
              <v-select v-model="leadsType.leadsProgramCode" label="description" :options="parameter.leadsProgramList" :reduce="x => x.code" />
              <small>Leads Program</small>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col md="4">
              <label>Leads</label>
            </b-col>
            <b-col md="8">
              <b-input v-model="leadsType.description" class="form-control" />
              <small>Leads name</small>
            </b-col>
          </b-row>
          <b-row v-if="leadsType.code != 'OWN'" class="mb-2">
            <b-col md="4">
              <label>Source</label>
            </b-col>
            <b-col md="8">
              <v-select
                v-model="leadsType.leadsSource"
                label="description"
                :options="leadsSourceOptions"
                :reduce="x => x.code"
                :disabled="leadsType === 'OWN'"
              />
              <small>Leads Source</small>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col>
              <b-btn block variant="danger" @click.stop="showModal = false">Cancel</b-btn>
            </b-col>
            <b-col>
              <b-btn block type="submit" variant="success">Save</b-btn>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>
